import axios from "axios";
import 'regenerator-runtime/runtime';

async function getInfos() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const azfalteMarkURL = urlParams.get("code-azfalte").toUpperCase();
  const azfalteMarkRegex = new RegExp("^([a-zA-Z]|[0-9]){4}$", "g");

  const bikeImg = document.getElementById("bike-img");
  const contentDiv = document.getElementById("content");
  const notFoundDiv = document.getElementById("not-found");

  if (azfalteMarkRegex.test(azfalteMarkURL)) {
    try {
      const result = await axios.get(
        `${process.env.API_URL}bike/${azfalteMarkURL}`
      );
      const data = result.data;
      const product = data.product;
      const organization = data.organization;
      const serials = data.serials;
      const leasingDuration = data.leasing_duration;
      const sessionStart = data.session_start;
      const services = data.services;
      const customerOrderId = data.customer_order_id;
      addServices(services);

      const brandModel = document.getElementById("brand-model");
      const characteristics = document.getElementById("characteristics");
      const organizationWrap = document.getElementById("organization-wrap");
      const azfalteMark = document.getElementById("azfalte-mark-wrap");
      const officialMark = document.getElementById("official-mark-wrap");
      const releaseDateWrap = document.getElementById("release-date-wrap");
      const leasingDurationWrap = document.getElementById(
        "leasing-duration-wrap"
      );
      const featuredMedia = product.media.find((media) => media.featured);
      if (featuredMedia || product.media[0])
        bikeImg.setAttribute(
          "src",
          featuredMedia ? featuredMedia.url : product.media[0].url
        );

      brandModel.textContent = product.brand.name + " " + product.model;
      characteristics.textContent = product.attributes
        .map((attr) => attr.name + " : " + attr.value)
        .join(" - ");

      addInformationTxt("Entreprise : ", organization.name, organizationWrap);

      serials.forEach((serial) => {
        if (serial.class === "azfalte") {
          addInformationTxt("Marquage Azfalte : ", azfalteMarkURL, azfalteMark);
        }

        if (serial.class === "official") {
          addInformationTxt("Marquage officiel : ", serial.value, officialMark);
        }
      });
      addInformationTxt(
        "Date de mise en circulation : ",
        new Date(sessionStart).toLocaleDateString("fr-FR"),
        releaseDateWrap
      );
      // addInformationTxt(
      //   "Durée de leasing : ",
      //   `${leasingDuration} mois`,
      //   leasingDurationWrap
      // );

      // services modal
      const serviceModalElem = document.getElementById("service-modal");
      serviceModalElem.addEventListener("click", (event) => {
        hideServiceModal();
      });

      const closeModalBtn = document.getElementById("close-modal");
      closeModalBtn.addEventListener("click", (event) => {
        hideServiceModal();
      });

      const modalTxtWrap = document.getElementById("modal-txt-wrap");
      modalTxtWrap.addEventListener("click", (event) => {
        event.stopPropagation();
      });

      const docsInfosData = await axios.get(`${process.env.API_URL}products/${product.id}/documentation`)
      const docsInfos = docsInfosData.data;
      if(docsInfos.exists) {
        const docsBtn = document.getElementById("docs-btn")
        docsBtn.classList.remove('hidden')
        docsBtn.href = docsInfos.url;
      } 

      const ticketBtn = document.getElementById("ticket-btn");
      ticketBtn.href = "https://helpdesk.azfalte.com/fr/knowledge";

      const foundBikeBtn = document.getElementById("found-btn");
      foundBikeBtn.href =
        "https://share.hsforms.com/1CUJ8Pjg6SW-CADJfMg_6lw5dl2i?marquage_azfalte=" +
        azfalteMarkURL;

      const orderBtn = document.getElementById("order-btn");
      orderBtn.href = "https://bike-planner.azfalte.com/tracking/" + customerOrderId;


      setTimeout(() => {
        parent.postMessage(contentDiv.scrollHeight, "*");
      }, 500);
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.error === "not_found"
      ) {
        contentDiv.classList.add("hidden");
        notFoundDiv.classList.remove("hidden");
        setTimeout(() => {
          parent.postMessage(notFoundDiv.scrollHeight, "*");
        }, 500);
      } else {
        console.log(e);
      }
    } finally {
      const loadingElems = document.querySelectorAll(".loading");
      loadingElems.forEach((elem) => {
        elem.classList.remove("loading");
      });
      bikeImg.classList.remove("img-loading");
    }
  }
}

function addServices(services) {
  if (services !== undefined && services.length) {
    const serviceListElem = document.getElementById("service-list");
    services.forEach((service, index) => {
      const serviceWrapDiv = document.createElement("div");
      serviceWrapDiv.classList = ["service-wrap"];
      serviceWrapDiv.id = "service-wrap-" + index;
      // serviceWrapDiv.addEventListener("click", (event) => {
      //   showServiceModal(index, services);
      // });
      const serviceTitleSpan = document.createElement("span");
      serviceTitleSpan.textContent = service.name;
      serviceTitleSpan.classList = ["service-title"];
      // const showDetailsSpan = document.createElement("span");
      // showDetailsSpan.classList = ["show-service-details"];
      // showDetailsSpan.textContent = "Voir détails";
      serviceWrapDiv.appendChild(serviceTitleSpan);
      // serviceWrapDiv.appendChild(showDetailsSpan);
      serviceListElem.appendChild(serviceWrapDiv);
    });
  } else {
    const serviceListWrap = document.getElementById("service-list-wrap");
    serviceListWrap.style.display = "none";
  }
}

function hideServiceModal() {
  const serviceModalElem = document.getElementById("service-modal");
  if ((serviceModalElem.style.opacity = "100%")) {
    serviceModalElem.style.opacity = "0";
    serviceModalElem.style.display = "none";
  }
}

function showServiceModal(index, services) {
  const modalTitle = document.getElementById("modal-title");
  modalTitle.textContent = services[index].name;
  const modalTxt = document.getElementById("modal-txt");
  modalTxt.textContent = services[index].description;

  const serviceModalElem = document.getElementById("service-modal");
  serviceModalElem.style.opacity = "100%";
  serviceModalElem.style.display = "grid";
}

function addInformationTxt(basePhrase, value, elem) {
  elem.textContent = basePhrase;
  const span = document.createElement("span");
  span.textContent = value;
  span.classList = ["bold"];
  elem.appendChild(span);
}

getInfos();
